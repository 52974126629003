import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isEmpty, get } from "lodash";

import { networkSelector } from "../../selectors/environment";

import { declineInvite, acceptInvite } from "../../actions/invites";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

// Reused in bitkom custom
export function AppointmentInviteMeta({
  status,
  accept,
  decline,
  canOptRetroactively,
  inviteStatus,
  totalAttendeesEnforcedAndExceeded,
}) {
  const buttons = totalAttendeesEnforcedAndExceeded ? (
    <div className="muted max-w-xs">
      {I18n.t(`js.calendars.appointment.total_attendees_exceeded`)}
    </div>
  ) : (
    <div className="btn-group w-full align-left">
      <button
        onClick={accept}
        className="btn btn-success"
        disabled={!!totalAttendeesEnforcedAndExceeded}
      >
        <i className="fa fa-plus mr-1" />
        {I18n.t("js.invites.actions.accept")}
      </button>
      <button onClick={decline} className="btn btn-danger">
        <i className="fa fa-xmark mr-1" />
        {I18n.t("js.invites.actions.reject")}
      </button>
    </div>
  );

  const hint = (
    <div className="appointment-invite-reaction">
      {status !== "open"
        ? I18n.t(`js.calendars.appointments.invite.${status}`)
        : null}
    </div>
  );

  switch (inviteStatus) {
    case "open":
      return status === inviteStatus ? buttons : hint;
    case "accepted":
    case "declined":
      return status === inviteStatus ? hint : null;
    case "expired":
      return status === "open" ? (canOptRetroactively ? buttons : null) : hint;
    case "obsolete":
      return status === inviteStatus ? hint : null;
    default:
      return null;
  }
}
AppointmentInviteMeta.propTypes = {
  status: PropTypes.string,
  accept: PropTypes.func,
  decline: PropTypes.func,
  canOptRetroactively: PropTypes.bool,
  totalAttendeesEnforcedAndExceeded: PropTypes.bool,
};

// Reused in bitkom custom
export function AppointmentInviteDate({ date }) {
  return (
    <div className="appointment-date">
      <time>
        {moment(date.start).format(
          I18n.t(
            date.all_day
              ? "js.time.formats.medium_date"
              : "js.time.formats.medium_date_and_time",
          ),
        )}
      </time>
      {date.end ? (
        <time>
          {" - " +
            moment(date.end).format(
              I18n.t(
                date.all_day
                  ? "js.time.formats.medium_date"
                  : date.same_day
                    ? "js.time.formats.medium_time"
                    : "js.time.formats.medium_date_and_time",
              ),
            )}
        </time>
      ) : null}
    </div>
  );
}
AppointmentInviteDate.propTypes = {
  all_day: PropTypes.bool,
  end: PropTypes.string,
  id: PropTypes.string,
  same_day: PropTypes.bool,
  start: PropTypes.string,
};

export default function AppointmentInvite({
  invitable,
  author,
  status,
  inviteStatus,
  hideMeta,
  id,
}) {
  const network = useSelector(networkSelector);
  const dispatch = useDispatch();

  const canOptRetroactively = get(network, [
    "config",
    "appointments",
    "opt_retroactively",
  ]);

  return (
    <div className="invite-container grid grid-cols-[1fr] sm:grid-cols-[30px_1fr_1fr] md:grid-cols-[30px_1.5fr_1fr] py-6 px-1.5 gap-2 sm:gap-3 md:gap-5">
      <FontAwesomeIcon
        className="fa-2xl hidden sm:block"
        icon={regular("calendar")}
      />
      <div className="invite-info">
        {!isEmpty(invitable) ? (
          <div>
            <div
              className={`appointment-name text-lg -mt-1 ${invitable.status}`}
            >
              <a href={`/appointments/${invitable.id}`}>{invitable.name}</a>
            </div>
            <AppointmentInviteDate date={invitable.date} />
          </div>
        ) : null}
        {!isEmpty(author) ? (
          <div className="invite-author">
            {I18n.t("js.invites.invited_by") + " "}
            <a href={`/members/${author.id}`}>{author.name}</a>
          </div>
        ) : null}
      </div>
      <div
        // When total_attendees_enforced_and_exceeded is true, text is displayed instead of buttons.
        // This should be able to shrink, but the buttons not.
        className={classnames(
          "appointment-invite-interactions col-span-2 sm:col-span-1",
          {
            "shrink-0": !invitable.total_attendees_enforced_and_exceeded,
          },
        )}
      >
        {!hideMeta ? (
          <AppointmentInviteMeta
            inviteStatus={inviteStatus}
            status={status}
            decline={() => dispatch(declineInvite({ inviteId: id }))}
            accept={() => dispatch(acceptInvite({ inviteId: id }))}
            canOptRetroactively={canOptRetroactively}
            totalAttendeesEnforcedAndExceeded={
              invitable.total_attendees_enforced_and_exceeded
            }
          />
        ) : null}
      </div>
    </div>
  );
}
AppointmentInvite.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  inviteStatus: PropTypes.string,
  author: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  decline: PropTypes.func,
  accept: PropTypes.func,
  invitable: PropTypes.shape({
    calendar_ids: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.shape(AppointmentInviteDate.propTypes),
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
  }),
  canOptRetroactively: PropTypes.bool,
  hideMeta: PropTypes.bool,
};
